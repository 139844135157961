import React from 'react';
import Image from 'gatsby-image';
import { Grid, Cell } from '@trbl/react-css-grid';
import ContentBlock from '../ContentBlock';
import ShapedImage from '../ShapedImage';
import CTACard from '../CTACard';
import Card from '../Card';

const BTFHome = React.memo(({ mainSection, CTA, machines, blockImage }) => {
  const { introSection, servicesTeaser } = mainSection;
  return (
    <>
      <section>
        <Grid>
          <Cell
            hStart={5}
            hSpan={9}
            hStartM={5}
            hSpanM={11}
            hStartS={2}
            hSpanS={14}
          >
            <ContentBlock
              headerAlign="right"
              header={introSection.heading}
              body={introSection.body}
            />
          </Cell>
        </Grid>
      </section>
      <section>
        <Grid>
          <Cell
            hStart={3}
            hSpan={6}
            hStartL={2}
            hSpanL={7}
            hSpanM={8}
            hStartS={2}
            hSpanS={14}
            id="home--fabrication-services"
          >
            <ContentBlock
              short
              header={servicesTeaser.heading}
              headerAlign={"center"}
              body={servicesTeaser.body}
              bodyAlign="center"
              button={{
                text: "Our Services",
                url: '/services/',
              }}
            />
          </Cell>
          <Cell
            hSpan={7}
            hSpanL={8}
            hSpanM={7}
            hSpanS={15}
            id="home--fabrication-services-image"
          >
            <div
              className="wmfab__parallax--parent"
            >
              <div
                className="wmfab__parallax--child"
              >
                <Image
                  fluid={servicesTeaser.image.childImageSharp.fluid}
                />
              </div>
            </div>
          </Cell>
        </Grid>
        <Grid
          id="home--featured"
        >
          <Cell
            hSpan={16}
            id="home--yellow-image-divider"
          >
            <ShapedImage
              color="gold"
              backgroundImage={blockImage}
            />
          </Cell>
          <Cell
            hStart={2}
            hSpan={9}
            hStartL={1}
            hSpanL={10}
            hSpanM={14}
            hStartS={2}
            hSpanS={14}
            id="home--cta-card-container"
          >
            <CTACard
              title={CTA.title}
              description={CTA.description}
              image={CTA.photo}
              button={CTA.button}
            />
          </Cell>
        </Grid>
      </section>
      <aside>
        <Grid
          id="home--machine-list"
        >
          <Cell
            hStart={3}
            hSpan={6}
            hStartL={2}
            hSpanL={6}
            hStartM={2}
            hSpanM={6}
            hStartS={3}
            hSpanS={12}
            id="home--machine-list-copy"
          >
            <ContentBlock
              header="Tools of the Trade"
              body="State of the art machines to meet all your fabrication needs."
              headerAlign="center"
              bodyAlign="center"
              button={{
                text: "Our Machines",
                url: "/machines/"
              }}
              short
            />
          </Cell>
          <Cell
            hSpan={6}
            hSpanL={8}
            hSpanM={8}
            hStartS={3}
            hSpanS={12}
          >
            <Grid>
              {machines.nodes.map(({
                childMarkdownRemark: {
                  fields: { slug },
                  frontmatter: {
                    title,
                    photo,
                  },
                },
              }) => {
                return (
                  <Cell
                    hSpan={3}
                    hSpanL={4}
                    hSpanM={4}
                    hSpanS={12}
                  >
                    <Card
                      name={title}
                      slug={`/machines${slug}`}
                      image={photo}
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Cell>
        </Grid>
      </aside>
    </>
  );
});

export default BTFHome
